<template>
  <div class="payment-proofs" v-if="canView">
    <page-header :title="title" icon="fa fa-object-group" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)" ref="excelMe">
      <div class="sub-menu" style="margin-bottom: 2px;">
        <b-row>
          <b-col>
            <b-form-select v-model="status" id="status" @change="reload()">
              <b-form-select-option
                v-for="elt of statusChoices"
                :key="elt.id"
                :value="elt.id"
              >
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col>
            <b-input type="text" v-model="entityFilter" placeholder="Filtrer par nom de famille">
            </b-input>
          </b-col>
          <b-col class="text-right">
            <pagination
              :pages-count="pagesCount"
              :has-next="hasNext"
              :has-previous="hasPrevious"
              :selector="paginationSelector"
              @change="onPageChanged($event)"
              css-style="justify-content: flex-end;"
            >
            </pagination>
          </b-col>
        </b-row>
      </div>
      <table class="table table-striped table-bordered">
        <tr v-if="invoices.length === 0">
          <td class="empty-text">Aucun élément</td>
        </tr>
        <tr v-else>
          <th colspan="2">Facture</th>
          <th>Famille</th>
          <th colspan="2">Preuve</th>
          <th>Commentaires</th>
          <th colspan="2">Actions</th>
        </tr>
        <tr v-for="invoice of filteredInvoices" :key="invoice.id">
          <td>{{ invoice.createdOn | dateToString('DD/MM/YYYY') }}</td>
          <td style="min-width: 150px">
            <invoice-badge :invoice="invoice"></invoice-badge>
          </td>
          <td>
            <a :href="getEntityLinkUrl(invoice, 'invoices')" @click.prevent="showEntitySidebar(invoice.entity)">
              {{ invoice.entity.name }}
            </a>
          </td>
          <td>
            <div v-if="invoice.paymentProof">
              {{ invoice.paymentProof.createdOn | dateToString('DD/MM/YYYY') }}
            </div>
          </td>
          <td>
            <div v-if="invoice.paymentProof">
              <a href @click.prevent="onViewDocument(invoice)">
                <i class="fa fa-download"></i> {{ invoice.paymentProof.fileName }}
              </a>
            </div>
          </td>
          <td style="width: 20%;" class="small-text">
            <div v-if="invoice.paymentProof" v-html="getProofComments(invoice.paymentProof, true)"></div>
            <div
              v-if="invoice.paymentProof && invoice.paymentProof.structureComments"
              v-html="getProofComments(invoice.paymentProof, false)"
              class="struct-comments"
            >
            </div>
          </td>
          <td class="text-center">
            <payment-button
              v-if="invoice.paymentProof && (invoice.toBePaidPrice() > 0)"
              css-class="btn btn-secondary btn-sm"
              :invoice="invoice"
              :entity="invoice.entity"
              :proof="invoice.paymentProof"
              @paid="reload()"
            ></payment-button>
          </td>
          <td class="text-center">
            <a v-if="invoice.paymentProof" href @click.prevent="refuseProof(invoice)" class="btn btn-secondary btn-sm">
              <i class="fa fa-close"></i> Refuser
            </a>
          </td>
        </tr>
      </table>
    </div>
    <confirm-modal
      name="refuse-payment-proof-status"
      :object="selectedInvoice"
      title="Preuve de paiement"
      :text="confirmStatusText"
      :show-comments="true"
      @confirmed="onRefuseProof"
    ></confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import Pagination from '@/components/Controls/Pagination.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import PaymentButton from '@/components/Payments/PaymentButton.vue'
import router from '@/router'
import { makeChoice } from '@/types/base'
import { makeInvoice } from '@/types/payments'
import { htmlText } from '@/utils/html'

export default {
  name: 'payment-proofs',
  props: {
  },
  mixins: [BackendMixin],
  components: {
    PaymentButton,
    ConfirmModal,
    Pagination,
    InvoiceBadge,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'payment-proofs',
      invoices: [],
      entityFilter: '',
      status: 1,
      statusChoices: [
        makeChoice({ id: 1, name: 'à vérifier', }),
        makeChoice({ id: 0, name: 'à fournir par la famille', }),
        makeChoice({ id: 2, name: 'acceptées', }),
        makeChoice({ id: 3, name: 'refusées', })
      ],
      startDate: null,
      endDate: null,
      count: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      selectedInvoice: null,
    }
  },
  created() {
    this.reload()
  },
  watch: {
  },
  computed: {
    title() {
      return 'Vérification des preuves de paiements'
    },
    confirmStatusText() {
      if (this.selectedInvoice) {
        return 'Refuser la preuve de paiement de la facture ' + this.selectedInvoice.getNumber()
      }
      return ''
    },
    canView() {
      return this.hasPerm('payments.view_paymentproof')
    },
    pagesCount() {
      return Math.ceil(this.count / 100)
    },
    paginationSelector() {
      return ''
    },
    filteredInvoices() {
      if (this.entityFilter) {
        const name = this.entityFilter.toLowerCase()
        return this.invoices.filter(
          invoice => invoice.entity.name.toLowerCase().indexOf(name) >= 0
        )
      }
      return this.invoices
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadProofs(page) {
      this.startLoading(this.loadingName)
      try {
        let url = '/api/payment-proofs/?page=' + page + '&status=' + this.status
        if (this.startDate) {
          url += '&start_date=' + this.startDate
        }
        if (this.endDate) {
          url += '&end_date=' + this.endDate
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.count = resp.data.count
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.invoices = resp.data.results.map(elt => makeInvoice(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getEntityLink(invoice, tab) {
      const params = { entityId: '' + invoice.entity.id, }
      const query = {
        tab: tab,
      }
      return { name: 'families-detail', params: params, query: query, }
    },
    getEntityLinkUrl(invoice, tab) {
      return router.resolve(this.getEntityLink(invoice, tab)).href
    },
    onPageChanged(event) {
      if (event.page !== this.page) {
        this.page = event.page
        this.loadProofs(this.page)
      }
    },
    reload() {
      this.page = 1
      this.loadProofs(this.page)
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      // this.reload()
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'preuves de paiements'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      return [
        {
          id: 4,
          label: 'Rafraîchir',
          callback: this.reload,
          icon: 'fa fa-refresh',
          cssClass: 'btn-secondary',
        },
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: 'btn-secondary',
        }
      ]
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'preuves de paiements'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onViewDocument(invoice) {
      const docUrl = '/download-payment-proof/<key>/'
      const docSlug = '' + invoice.id
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getProofComments(proof, user) {
      return htmlText(user ? proof.comments : proof.structureComments)
    },
    refuseProof(invoice) {
      this.selectedInvoice = invoice
      const that = this
      this.$nextTick(() => {
        that.$bvModal.show('bv-confirm-modal:refuse-payment-proof-status')
      })
    },
    async onRefuseProof(event) {
      this.startLoading(this.loadingName)
      try {
        let url = '/api/payment-proofs/' + event.object.paymentProof.id + '/'
        const data = {
          'structure_comments': event.comments,
          'status': 3,
        }
        let backendApi = new BackendApi('post', url)
        await backendApi.callApi(data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      this.reload()
    },
  },
}
</script>

<style scoped lang="less">
.school-years {
  background: #eee;
}
.school-years .badge {
  display: inline-block;
  margin-right: 2px;
}
.table-sub-data tr td,
.table-sub-data tr th {
  font-size: 0.7em;
  background: transparent !important;
}
.struct-comments {
  padding: 2px;
  background: #222;
  color: #fff;
  margin-top: 2px;
}
</style>
